<template lang="pug">
  portal(to="toolbar-search")
    live-search-provider(#default="liveSearch")
      live-search(
        data-test="candidate-search"
        :list="liveSearch.list"
        :loading="liveSearch.loading"
        @onFocusToggle="liveSearch.updateFocused"
        @searching="liveSearch.search"
        :value="$route.query.search"
        placeholder='Search by CBR account, client name, data etc…'
      ).search
        template(v-slot:default="liveSearchWrapper")
          search(
            :value="$route.query.search"
            placeholder="Search"
            @input="liveSearchWrapper.onType"
            @onFocusToggle="liveSearchWrapper.onFocusToggle"
            @enter-key-pressed="updateSearch($event, liveSearchWrapper.hideLiveSearch)"
            @change="onChange"
          ).search
        template(v-slot:live-search="liveSearchItems")
          live-search-item(v-for="item in liveSearch.list" :key="item.id" :item="item" @click="onLiveSearchClick(item, liveSearchItems.select)")
</template>

<script>
export default {
  props: {
    loading: Boolean
  },

  methods: {
    async updateSearch(val, hideLiveSearch) {
      try {
        hideLiveSearch && hideLiveSearch();
        await this.$router.push({query: {...this.$route.query, page: 1, search: val || undefined}})
        this.change()
      } catch (e) {}
    },

    change() {
      this.$emit('change')
    },

    onLiveSearchClick(item, select) {
      setTimeout(async () => {
        let toRouteName = this.getItemRouteName(item)
        if (item.is_landing) {
          select(item.application_id);
          await this.updateSearch(item.application_id);
        } else await this.$router.push({ name: toRouteName, query: { id: item.id || undefined, search: undefined } })
      }, 0);
    },

    getItemRouteName(item) {
      if (item.is_crm) return this.$ROUTER_NAMES.CANDIDATES_ALL
      else return this.$ROUTER_NAMES.TASK_APPLICATIONS
    },

    async onChange(val) {
      try {
        await this.$router.push({ query: { ...this.$route.query, page: 1, search: val || undefined } })
        this.change()
      } catch (e) {}
    }
  },

  components: {
    search: () => import('@/components/global/SearchNew.vue'),
    liveSearch: () => import('@/components/global/LiveSearch/LiveSearch.vue'),
    liveSearchItem: () => import('@/components/global/LiveSearch/LiveSearchItem.vue'),
    liveSearchProvider: () => import('@/app/admin/components/LiveSearchProvider.vue')
  }
}
</script>

<style lang="scss" scoped>
.search {
  font-family: $font;
  max-width: 900px;
}
</style>
